<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark>
      <img
        src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
        alt="Logo HEIG-VD"
        height="70%"
        class="logo"
      />
      <div class="d-flex align-center ml-5">DEMANDES D'ÉQUIVALENCE</div>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <v-row class="mt-10 mb-0" justify="center">
          <v-col cols="12" sm="12" lg="10">
            <router-link to="/equivalences?status=submitted,valid"
              >En cours</router-link
            >
            <div class="separator mx-3">|</div>
            <router-link to="/equivalences?status=invalid,complete,closed"
              >Historique</router-link
            >
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="12" lg="10"
            ><router-view
              :formatDate="formatDate"
              :downloadBundle="downloadBundle"
              :downloading="downloading"
          /></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserProfile from "@/components/UserProfile";
import { format, parseISO } from "date-fns";
import fileDownload from "js-file-download";
import axios from "axios";

export default {
  name: "App",
  components: { UserProfile },
  data: () => ({
    downloading: false,
  }),
  methods: {
    formatDate: function (value) {
      return format(parseISO(value), "dd.MM.yyyy HH:mm");
    },
    async downloadBundle(_id) {
      this.downloading = _id;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/equivalences/${_id}?format=pdf`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "demande-d-equivalence.pdf");
      this.downloading = false;
    },
  },
};
</script>

<style>
.separator {
  display: inline-block;
}
</style>