var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.equivalence.status === 'complete' || (this.$keycloak.hasResourceRole('admin') && _vm.langModuleSelected)}},[_c('div',{staticClass:"mb-5"},[_vm._v("Statut demande")]),_c('v-select',{attrs:{"outlined":"","dense":"","label":"Statut","items":['submitted', 'invalid'].indexOf(_vm.equivalence.status) === -1 &&
      _vm.equivalence.modules.map(function (m) { return m.managerStatus; }).indexOf('pending') ===
        -1
        ? _vm.statuses
        : _vm.statuses.filter(function (s) { return s.value !== 'complete'; }),"disabled":this.$route.name === 'Equivalences create'},model:{value:(_vm.equivalence.status),callback:function ($$v) {_vm.$set(_vm.equivalence, "status", $$v)},expression:"equivalence.status"}}),(_vm.equivalence.status === 'invalid')?_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Raison de l'invalidité","auto-grow":""},model:{value:(_vm.equivalence.invalidReason),callback:function ($$v) {_vm.$set(_vm.equivalence, "invalidReason", $$v)},expression:"equivalence.invalidReason"}}):_vm._e(),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v("Demandeur")]),(!_vm.editMode)?_c('v-autocomplete',{attrs:{"items":_vm.students,"search-input":_vm.searchValue,"label":"Demandeur *","outlined":"","dense":"","no-data-text":"Tapez le nom d'une personne..."},on:{"keydown":_vm.searchStudents,"update:searchInput":function($event){_vm.searchValue=$event},"update:search-input":function($event){_vm.searchValue=$event}},model:{value:(_vm.equivalence.requester),callback:function ($$v) {_vm.$set(_vm.equivalence, "requester", $$v)},expression:"equivalence.requester"}}):_c('v-text-field',{attrs:{"disabled":"","outlined":"","dense":"","label":"Demandeur","value":((_vm.equivalence.requester.firstname) + " " + (_vm.equivalence.requester.lastname) + " (" + (_vm.equivalence.requester.email) + ")")}}),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v("Modules sélectionnés")]),_c('v-autocomplete',{attrs:{"label":"Modules *","items":_vm.equivalence && _vm.equivalence.requester
        ? _vm.modules.filter(function (m) { return m.filiere === _vm.equivalence.requester.filiere; })
        : [],"item-text":function (item) { return ((item.name) + " (" + (item.orientation) + ")"); },"item-value":"_id","outlined":"","dense":"","multiple":"","disabled":(_vm.equivalence && !_vm.equivalence.requester) || _vm.editMode},model:{value:(_vm.selectedModules),callback:function ($$v) {_vm.selectedModules=$$v},expression:"selectedModules"}}),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v("Détails des modules")]),_vm._l((_vm.equivalence.modules),function(module){return _c('div',{key:module._id},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-autocomplete',{attrs:{"label":("Type d'étude pour " + (module.name || module.basemodule.name) + " *"),"items":_vm.studyTypes,"outlined":"","dense":""},model:{value:(module.studyType),callback:function ($$v) {_vm.$set(module, "studyType", $$v)},expression:"module.studyType"}}),_c('v-textarea',{attrs:{"outlined":"","dense":"","label":("Explication pour " + (module.name || module.basemodule.name) + " *"),"auto-grow":""},model:{value:(module.description),callback:function ($$v) {_vm.$set(module, "description", $$v)},expression:"module.description"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(module.managerStatus)?_c('v-select',{attrs:{"outlined":"","dense":"","label":"Statut RF","items":_vm.managerStatuses,"hint":module.updatedByManager && module.updatedAtManager
                ? ((module.updatedByManager.firstname) + " " + (module.updatedByManager.lastname) + " (" + (_vm.formatDate(module.updatedAtManager)) + ")")
                : undefined,"persistent-hint":""},on:{"change":function($event){return _vm.resetUnitiesAndComments(module)}},model:{value:(module.managerStatus),callback:function ($$v) {_vm.$set(module, "managerStatus", $$v)},expression:"module.managerStatus"}}):_vm._e(),(
              [
                'accepted-partial',
                'exemption-accepted-partial',
                'other' ].indexOf(module.managerStatus) > -1
            )?_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Unités concernées","auto-grow":""},model:{value:(module.unities),callback:function ($$v) {_vm.$set(module, "unities", $$v)},expression:"module.unities"}}):_vm._e(),(module.managerStatus)?_c('v-textarea',{attrs:{"outlined":"","dense":"","label":"Remarques","auto-grow":""},model:{value:(module.comments),callback:function ($$v) {_vm.$set(module, "comments", $$v)},expression:"module.comments"}}):_vm._e()],1)],1)],1)],1)}),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v("Documents")]),_c('v-file-input',{attrs:{"label":"Justificatif supplémentaire","outlined":"","dense":"","value":_vm.getFilename('admin'),"loading":_vm.uploading['admin'],"accept":".pdf","hint":"Fichier au format PDF","truncate-length":"100","multiple":""},on:{"change":function (file) {
        _vm.handleUpload('admin', file);
      },"click:clear":function($event){return _vm.removeDocument('admin')}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }