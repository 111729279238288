<template>
  <div>
    <v-alert
      dense
      type="info"
      icon="mdi-translate"
      outlined
      v-if="
        this.$keycloak.hasResourceRole('admin') &&
        equivalence &&
        equivalence.modules &&
        equivalence.modules.map((m) => m.basemodule.lang).indexOf(true) > -1
      "
    >
      Il s'agit d'une demande pour des modules de <strong>langue</strong>.
    </v-alert>
    <v-card outlined v-if="equivalence">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-btn
              text
              small
              plain
              class="mt-2"
              @click="$router.go(-1)"
              :ripple="false"
              ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
            ></v-col
          >
          <v-col class="text-right">
            <v-btn
              text
              @click.stop="downloadBundle(equivalence._id)"
              :loading="downloading && downloading === equivalence._id"
              small
              plain
              :ripple="false"
              class="mt-1"
              >Télécharger le dossier
              <v-icon right dark class="mb-1">
                mdi-cloud-download-outline
              </v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-title class="pa-10 font-weight-light"
        >Demande d'équivalence</v-card-title
      >
      <v-card-subtitle
        class="px-10"
        v-if="equivalence.modules[0].basemodule.manager.length > 1"
        >Responsables :
        {{
          equivalence.modules[0].basemodule.manager
            .map((m) => `${m.firstname} ${m.lastname}`)
            .join(", ")
        }}</v-card-subtitle
      >
      <v-card-subtitle class="px-10" v-else
        >Responsable :
        {{
          equivalence.modules[0].basemodule.manager
            .map((m) => `${m.firstname} ${m.lastname}`)
            .join(", ")
        }}</v-card-subtitle
      >
      <v-card-text class="px-10 font-weight-light">
        <equivalence-form
          :equivalence="equivalence"
          v-if="equivalence"
          :formatDate="formatDate"
        />

        <div
          v-if="
            ['submitted', 'invalid', 'closed'].indexOf(equivalence.status) ===
              -1 &&
            equivalence.modules
              .map((m) => m.managerStatus)
              .indexOf('pending') === -1 &&
            equivalence.decisionDocuments &&
            equivalence.decisionDocuments.length
          "
        >
          <v-divider></v-divider>
          <div class="my-3">Décisions générées</div>
          <div
            v-for="document in equivalence.decisionDocuments"
            :key="document._id"
          >
            <div>Généré le {{ formatDate(document.generatedAt) }}</div>
            <a @click="downloadDocument(document.name)">decision.pdf</a>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          plain
          @click="submit"
          :ripple="false"
          :loading="loading.submit"
          :disabled="
            this.$keycloak.hasResourceRole('admin') &&
            equivalence &&
            equivalence.modules &&
            equivalence.modules.map((m) => m.basemodule.lang).indexOf(true) > -1
          "
        >
          Mettre à jour la demande
        </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.visible = false">
            Fermer
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import EquivalenceForm from "@/components/EquivalenceForm.vue";
import fileDownload from "js-file-download";

export default {
  name: "Create",
  components: { EquivalenceForm },
  props: ["downloadBundle", "downloading", "formatDate"],
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/v2/equivalences/${this.$route.params._id}`,
    });
    this.equivalence = data;
  },
  data: () => ({
    loading: { submit: false },
    equivalence: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    async submit() {
      try {
        this.loading.submit = true;
        const { data } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/admin/v2/equivalences/${this.equivalence._id}`,
          data: {
            ...this.equivalence,
          },
        });
        this.loading.submit = false;
        this.dialog = false;
        this.equivalence = data;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "La demande a été mise à jour.",
        };
      } catch (error) {
        console.log(error);
        this.loading.submit = false;
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de la soumission.",
        };
      }
    },
    async downloadDocument(name) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${name}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "decision.pdf");
    },
  },
  computed: {
    validForm() {
      return (
        this.equivalence.modules.length > 0 &&
        this.equivalence.modules.map((m) => "studyType" in m).indexOf(false) ===
          -1 &&
        [...new Set(this.equivalence.modules.map((m) => m.studyType))].filter(
          (m) => m
        ).length === this.equivalence.documents.length
      );
    },
  },
};
</script>s