<template>
  <v-card>
    <v-card-title>
      Demandes
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Rechercher"
        single-line
        hide-details
        outlined
        dense
      ></v-text-field>
      <v-btn
        text
        plain
        :ripple="false"
        @click="$router.push('/equivalences/create')"
        >Nouvelle demande</v-btn
      >
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="equivalences"
      :options.sync="options"
      :server-items-length="total"
      :loading="loading"
      class="elevation-1"
      locale="fr-CH"
      :search="search"
      :footer-props="{
        itemsPerPageOptions: [10, 30, 50],
        itemsPerPageText: 'Élements par page',
      }"
    >
      <template v-slot:body="{ items }">
        <tbody class="text-left">
          <tr
            v-for="item in items"
            :key="item._id"
            @click="$router.push(`/equivalences/${item._id}`)"
          >
            <td>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small :color="item.modules.map((m) => m.basemodule.lang).indexOf(true) >
                      -1 ? 'grey' : 'success'" dark v-bind="attrs" v-on="on">
                    {{
                      item.modules.map((m) => m.basemodule.lang).indexOf(true) >
                      -1
                        ? 'mdi-translate'
                        : 'mdi-puzzle-outline'
                    }}
                  </v-icon>
                </template>
                <span>      {{
                      item.modules.map((m) => m.basemodule.lang).indexOf(true) >
                      -1
                        ? 'Langue'
                        : 'Module'
                    }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.requester.lastname }}</td>
            <td>{{ item.requester.firstname }}</td>
            <td>{{ item.requester.filiere }}</td>
            <td>{{ item.modules.map((m) => m.basemodule.name).join(", ") }}</td>
            <td>
              <v-chip
                color="grey"
                text-color="white"
                v-if="item.status === 'submitted'"
                small
                >À traiter</v-chip
              >
              <v-chip
                small
                color="orange"
                text-color="white"
                v-if="item.status === 'valid'"
                >Valide</v-chip
              >
              <v-chip
                small
                color="red"
                text-color="white"
                v-if="item.status === 'invalid'"
                >Invalide</v-chip
              >
              <v-chip
                small
                color="green"
                text-color="white"
                v-if="item.status === 'complete'"
                >Terminée</v-chip
              >
              <v-chip
                small
                color="grey"
                text-color="white"
                v-if="item.status === 'closed'"
                >Clos sans suite</v-chip
              >
            </td>
            <td>
              {{
                item.modules.filter(
                  (module) =>
                    module.managerStatus && module.managerStatus !== "pending"
                ).length
              }}/{{ item.modules.length }}
            </td>
            <td>
              {{ item.over ? "Oui" : "Non" }}
            </td>
            <td>{{ formatDate(item.createdAt) }}</td>
            <td>{{ formatDate(item.updatedAt) }}</td>
            <td>
              <v-btn
                text
                @click.stop="downloadBundle(item._id)"
                :disabled="downloading && downloading !== item._id"
                :loading="downloading && downloading === item._id"
                small
                plain
                :ripple="false"
                >Dossier</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
      <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
        >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
      >
    </v-data-table>
  </v-card>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";

export default {
  props: ["formatDate", "downloadBundle", "downloading"],
  data: () => ({
    equivalences: [],
    options: {},
    total: 0,
    loading: false,
    search: "",
    headers: [
      {
        text: "Type",
        value: "type",
        sortable: false,
      },
      {
        text: "Nom",
        value: "orderId",
        sortable: false,
      },
      {
        text: "Prénom",
        value: "value",
        sortable: false,
      },
      {
        text: "Filière",
        value: "filiere",
        sortable: false,
      },
      {
        text: "Modules",
        value: "modules",
        sortable: false,
      },
      {
        text: "Statut",
        value: "status",
        sortable: false,
      },
      {
        text: "Validations RF",
        sortable: false,
      },
      {
        text: "Traitement terminé",
        sortable: false,
      },
      {
        text: "Date de création",
        value: "createdAt",
        sortable: false,
      },
      {
        text: "Date de mise à jour",
        value: "updatedAt",
        sortable: false,
      },
      {
        text: "Téléchargements",
        value: "downloads",
        sortable: false,
      },
    ],
  }),
  watch: {
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.equivalences = data.items;
        this.total = data.total;
      },
      deep: true,
    },
    "$route.query.status": {
      async handler() {
        this.options = { page: 1, itemsPerPage: 10 };
      },
      deep: true,
    },
    dialog(after) {
      if (!after) {
        this.initialData = undefined;
      }
    },
    search: debounce(function (newValue) {
      this.$router
        .push({
          query: {
            ...this.$route.query,
            search: newValue || undefined,
          },
        })
        .catch(() => {});
    }, 500),
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/v2/equivalences?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&status=${this.$route.query.status}&search=${this.search}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
  },
};
</script>