<template>
  <v-card outlined>
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            text
            small
            plain
            class="mt-2"
            @click="
              equivalence.requester && !!equivalence.requester._id
                ? $router.go(-2)
                : $router.go(-1)
            "
            :ripple="false"
            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-card-title class="pa-10 font-weight-light"
      >Nouvelle demande d'équivalence</v-card-title
    >
    <v-card-text class="px-10 font-weight-light">
      <equivalence-form :equivalence="equivalence" />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        plain
        @click="submit"
        :disabled="!validForm"
        :loading="loading.submit"
        :ripple="false"
      >
        Enregistrer la demande
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" width="500px"
      ><v-card>
        <v-card-title>
          <span class="headline font-weight-light">Information importante</span>
        </v-card-title>
        <v-card-text class="font-weight-light">
          Une fois soumise, votre demande ne sera plus modifiable. Êtes-vous
          certain de vouloir soumettre votre demande?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
            :disabled="loading.submit"
            :ripple="false"
            plain
          >
            Annuler
          </v-btn>
          <v-btn
            text
            @click="submit"
            :loading="loading.submit"
            :ripple="false"
            plain
          >
            Soumettre
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-card>
</template>

<script>
import axios from "axios";
import EquivalenceForm from "@/components/EquivalenceForm.vue";
export default {
  name: "Create",
  components: { EquivalenceForm },
  data: () => ({
    loading: { save: false, submit: false },
    dialog: false,
    equivalence: {
      year: 2022,
      modules: [],
      documents: [],
    },
    _id: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    async submit() {
      try {
        this.loading.submit = true;
        await axios({
          method: this._id ? "patch" : "post",
          url: this._id
            ? `${process.env.VUE_APP_API_URI}/admin/v2/equivalences/${this._id}`
            : `${process.env.VUE_APP_API_URI}/admin/v2/equivalences`,
          data: {
            ...this.equivalence,
          },
        });
        localStorage.setItem(
          "snackbar",
          JSON.stringify({
            visible: true,
            color: "success",
            text: "Votre demande a été soumise.",
          })
        );
        this.$router.push("/equivalences?status=valid");
      } catch (error) {
        this.loading.submit = false;
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de la soumission.",
        };
      }
    },
  },
  computed: {
    validForm() {
      return (
        this.equivalence.requester &&
        this.equivalence.modules.length > 0 &&
        this.equivalence.modules
          .map(
            (m) =>
              "studyType" in m && "description" in m && m.description.length > 0
          )
          .indexOf(false) === -1
      );
    },
  },
};
</script>