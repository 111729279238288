<template>
  <v-form :disabled="equivalence.status === 'complete' || (this.$keycloak.hasResourceRole('admin') && langModuleSelected)">
    <div class="mb-5">Statut demande</div>
    <v-select
      outlined
      dense
      label="Statut"
      :items="
        ['submitted', 'invalid'].indexOf(equivalence.status) === -1 &&
        equivalence.modules.map((m) => m.managerStatus).indexOf('pending') ===
          -1
          ? statuses
          : statuses.filter((s) => s.value !== 'complete')
      "
      v-model="equivalence.status"
      :disabled="this.$route.name === 'Equivalences create'"
    ></v-select>
    <v-textarea
      outlined
      dense
      label="Raison de l'invalidité"
      v-if="equivalence.status === 'invalid'"
      v-model="equivalence.invalidReason"
      auto-grow
    ></v-textarea>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Demandeur</div>
    <v-autocomplete
      v-if="!editMode"
      v-model="equivalence.requester"
      :items="students"
      @keydown="searchStudents"
      :search-input.sync="searchValue"
      label="Demandeur *"
      outlined
      dense
      no-data-text="Tapez le nom d'une personne..."
    >
    </v-autocomplete>
    <v-text-field
      v-else
      disabled
      outlined
      dense
      label="Demandeur"
      :value="`${equivalence.requester.firstname} ${equivalence.requester.lastname} (${equivalence.requester.email})`"
    ></v-text-field>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Modules sélectionnés</div>
    <v-autocomplete
      label="Modules *"
      :items="
        equivalence && equivalence.requester
          ? modules.filter((m) => m.filiere === equivalence.requester.filiere)
          : []
      "
      :item-text="(item) => `${item.name} (${item.orientation})`"
      item-value="_id"
      v-model="selectedModules"
      outlined
      dense
      multiple
      :disabled="(equivalence && !equivalence.requester) || editMode"
    ></v-autocomplete>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Détails des modules</div>
    <div v-for="module in equivalence.modules" :key="module._id">
      <v-container class="pa-0">
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="module.studyType"
              :label="`Type d'étude pour ${
                module.name || module.basemodule.name
              } *`"
              :items="studyTypes"
              outlined
              dense
            ></v-autocomplete>
            <v-textarea
              outlined
              dense
              :label="`Explication pour ${
                module.name || module.basemodule.name
              } *`"
              v-model="module.description"
              auto-grow
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              outlined
              dense
              label="Statut RF"
              v-if="module.managerStatus"
              :items="managerStatuses"
              v-model="module.managerStatus"
              @change="resetUnitiesAndComments(module)"
              :hint="
                module.updatedByManager && module.updatedAtManager
                  ? `${module.updatedByManager.firstname} ${
                      module.updatedByManager.lastname
                    } (${formatDate(module.updatedAtManager)})`
                  : undefined
              "
              persistent-hint
            ></v-select>
            <v-textarea
              outlined
              dense
              label="Unités concernées"
              v-if="
                [
                  'accepted-partial',
                  'exemption-accepted-partial',
                  'other',
                ].indexOf(module.managerStatus) > -1
              "
              v-model="module.unities"
              auto-grow
            ></v-textarea>
            <v-textarea
              outlined
              dense
              label="Remarques"
              v-model="module.comments"
              v-if="module.managerStatus"
              auto-grow
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">Documents</div>
    <v-file-input
      :label="`Justificatif supplémentaire`"
      outlined
      dense
      @change="
        (file) => {
          handleUpload('admin', file);
        }
      "
      :value="getFilename('admin')"
      @click:clear="removeDocument('admin')"
      :loading="uploading['admin']"
      accept=".pdf"
      hint="Fichier au format PDF"
      truncate-length="100"
      multiple
    ></v-file-input>
  </v-form>
</template>

<script>
import axios from "axios";
import _ from "lodash";

export default {
  props: ["equivalence", "formatDate"],
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/admin/v2/basemodules`,
    });
    this.modules = data;
    this.editMode = !!this.equivalence._id;
    this.selectedModules = this.equivalence.modules.map(
      (m) => m.basemodule._id
    );
    if (this.$route.name === "Equivalences create") {
      this.equivalence.status = "valid";
    }
  },
  data: () => ({
    modules: [],
    selectedModules: [],
    studyTypes: [
      "HES-SO",
      "EPFL",
      "Université",
      "Autre HES",
      "Autre école suisee (ET, ES, ...)",
      "École étrangère",
      "Autre situation",
    ],
    statuses: [
      { text: "À traiter", value: "submitted" },
      { text: "Valide", value: "valid" },
      { text: "Invalide", value: "invalid" },
      { text: "Clos sans suite ", value: "closed" },
      { text: "Terminée", value: "complete" },
    ],
    managerStatuses: [
      { text: "En attente", value: "pending" },
      { text: "Équivalence accordée sur le module complet", value: "accepted" },
      {
        text: "Équivalence partielle sur certaines unités",
        value: "accepted-partial",
      },
      {
        text: "Dispense accordée sur le module complet",
        value: "exemption-accepted",
      },
      {
        text: "Dispense partielle sur certaines unités",
        value: "exemption-accepted-partial",
      },
      { text: "Autre situation - panachage", value: "other" },
      { text: "Aucune équivalence accordée", value: "rejected" },
    ],
    searchValue: "",
    students: [],
    uploading: {},
    editMode: undefined,
  }),
  methods: {
    updateModules(modules) {
      this.equivalence.modules = this.equivalence.modules.filter(
        (m) => modules.indexOf(m.name) > -1
      );
      modules.map((name) => {
        if (!this.equivalence.modules.find((m) => m.name === name)) {
          this.equivalence.modules.push({ name });
        }
      });
    },
    async handleUpload(type, files) {
      if (files && files.length) {
        files.map(async (file) => {
          if (file && file.size) {
            this.uploading[type] = true;
            try {
              this.equivalence.documents = this.equivalence.documents.filter(
                (d) => d.type !== type
              );
              const formData = new FormData();
              formData.append("file", file);
              const { data } = await axios({
                method: "post",
                url: `${process.env.VUE_APP_API_URI}/uploads`,
                data: formData,
                headers: {
                  "content-Type": "multipart/form-data",
                },
              });
              this.equivalence.documents.push({ type, student: data.filename });
              this.uploading[type] = false;
            } catch (e) {
              this.uploading[type] = false;
              console.log(e);
            }
          }
        });
      }
    },
    removeDocument(type) {
      this.equivalence.documents = this.equivalence.documents.filter(
        (d) => d.type !== type
      );
    },
    getFilename(studyType) {
      if (
        this.equivalence.documents.filter((d) => d.type === studyType)
          .length === 1
      ) {
        return this.equivalence.documents.find((d) => d.type === studyType)
          ? new File(
              [""],
              this.equivalence.documents
                .find((d) => d.type === studyType)
                .student.substring(22),
              { type: "text/plain" }
            )
          : undefined;
      }

      if (
        this.equivalence.documents.filter((d) => d.type === studyType).length >
        1
      ) {
        return new File(
          [""],
          `${
            this.equivalence.documents.filter((d) => d.type === studyType)
              .length
          } fichiers`,
          { type: "text/plain" }
        );
      }
    },
    async queryPicker(v) {
      this.loading = true;
      const { data: contacts } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/v2/students?search=${v}`,
      });
      this.students = contacts.map((c) => ({
        text: `${c.firstname} ${c.lastname}`,
        value: c,
      }));
      this.loading = false;
    },
    clearSearchStudents() {
      this.searchStudents = null;
    },
    resetUnitiesAndComments(module) {
      module.comments = "";
      module.unities = "";
    },
    searchStudents: _.debounce(function () {
      this.searchValue && this.queryPicker(this.searchValue, "students");
    }, 300),
  },
  computed: {
    langModuleSelected() {
      return (
        this.equivalence.modules
          .map((m) => m.lang || (m.basemodule && m.basemodule.lang))
          .indexOf(true) > -1
      );
    },
  },
  watch: {
    "equivalence._id": function () {
      this.editMode = this.equivalence._id;
    },
    "equivalence.requester": function (after, before) {
      if (before && before._id !== after._id) {
        this.selectedModules = [];
      }
    },
    selectedModules(value) {
      this.equivalence.modules = this.modules
        .filter((module) => value.indexOf(module._id) > -1)
        .map((module) => {
          return (
            this.equivalence.modules.find(
              (m) => m.basemodule && m.basemodule._id === module._id
            ) || module
          );
        });
    },
  },
};
</script>